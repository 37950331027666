body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
    margin: auto;
    border: 3px solid black;
    padding: 10px;
    vertical-align: central;
    text-align: center;
}

.templateButtonLeft {
    margin: auto;
    margin-right: 5%;
}

.templateButtonRight {
    margin: auto;
    margin-left: 5%;
}

.templateButton {
    width: 150px;
}

.mobileCenter {
    margin: auto;
    padding: 10px;
    vertical-align: central;
    text-align: center;
}

.reload {
    font-family: Lucida Sans Unicode
}

.centerBorderless {
    margin: auto;
    border: 0px solid black;
    padding: 10px;
    vertical-align: central;
    text-align: center;
}

.translateDropdown {
    width: 75%;
    height: 50px;
    font-size: 24px;
}

.translateDropdownMobile {
    width: 100%;
    height: 50px;
    font-size: 18px;
}

.wiki {
    border: 1px solid black;
}

.copy-icon {

}
/*#map_node {*/
/*    width: 500px;*/
/*    height: 500px;*/
/*    padding: 0px;*/
/*}*/

.shutter {
    opacity: 1;
    transition: all 30ms ease-in;
}

.hidden {
    visibility: hidden;
}

.img {
    width: 100%;
    height: 500px;
    margin: 0 auto;
}

/*.alignImg {*/
/*    min-width: 300px;*/
/*    min-height: 300px;*/
/*}*/

.alignImg {
    justifyContent: center;
    display: flex;
}

.shutter.on {
    opacity: 0.5;
}

.districtText {
    font: italic bold 18px sans-serif;
    fill: white;
}

.centertext {
    text-align: center;
}

input[type=number]{
    -moz-appearance: textfield;
}

dd {
    margin-top: -5px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.tableRed {
    background-color: rgba(255,0,17,0.2);
}

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.cellborder {
    border: 1px solid black;
    text-align: center;
    margin: 0 auto;
    height: 80px;
}

.cellcontent {
    margin: 0% 0%;

    width: 100%;
    height: 100%;
}

.centerObj {
    padding: 10px;
    vertical-align: central;
    text-align: center;
    horiz-align: center;
}

.vert {
    margin: 0% !important;
    vertical-align: center;
}

.inittable {
    border-collapse: collapse;
    margin: auto;
    table-layout: fixed;
}

.trbig {
    line-height: 40px;
}

.trshort {
    padding-left: 100px;
}

.inittablesmall {
    width: 15%;
}

.inittabletiny {
    width: 5%;
}

.inittablehalfsmall {
    width: 10%;
}

.colorgreen {
    background-color: chartreuse;
}

.noborder {
    border: 0;
}

.transparentbg {
    background-color: transparent;
}

.tdspecial {
    position: relative;
}

.it_wrapper {
    display: grid;
    grid-template-columns: 10% 10% 25% 10% 10% 25% 10%;
    color: #444;
    transition: all 0.5s ease-in-out;
}

.inputNone {
    outline: none;
}

.it_cell {
    width: 100%;
    height: 60px;
    color: black;
    font-size: 150%;
    position: relative;
}

.it_cell_border_sides {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
}

.it_cell_border_top {

}

.it_cell_border_bottom {

}

.it_top_left {
    border-top-left-radius: 5px;
}
.it_top_right {
    border-top-right-radius: 5px;
}
.it_bottom_left {
    border-bottom-left-radius: 5px;
}
.it_bottom_right {
    border-bottom-right-radius: 5px;
}

.buttonTextBig {
    font-size: 150%;
    font-weight: bold;
    text-align: center;
    margin: 0;
}

.healthbar {
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
}

.fill {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0%;
    margin: 0px;
    text-align: center;
}

.halffill {
    text-align: center;
    width: 40%;
    height: 100%;
    overflow: hidden;
    padding: 0%;
    margin: 0px;
}

.short {
    width: 10%;
}

.alignLeft {
    text-align: left;
}

.wiki-align {
    margin-top: 200px;
}

#patreonBox {
    background-color: #FF424D ;
    padding: 4px ;
}

#discordBox {
    background-color: #5865F2 ;
    padding: 4px ;
    border-radius: 5px;
}

.noblueline{
    text-decoration: none
}

.fakelinkCurrent {
    cursor:pointer;
    color:black;
    text-decoration:underline;
}

.fakelink {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

.fakelinkWhite {
    cursor:pointer;
    color: white;
    text-decoration:underline;
}

.fakelinkDark {
    cursor:pointer;
    color:darkslateblue;
    text-decoration:underline;
}

.wrap-collabsible {
    margin-bottom: 1.2rem 0;
}

.inputCheckNone {
    display: none;
}

.modal-90w {
    max-width: 50% !important;
}

.modal-header {
    margin-right: auto;
}

.lbl-toggle {
    display: block;

    font-weight: bold;
    font-family: monospace;
    font-size: 1.2rem;
    text-transform: uppercase;
    text-align: center;

    padding: 1rem;

    color: #fcfcfc;
    background: #455dbb;

    cursor: pointer;

    border-radius: 7px;
    transition: all 0.25s ease-out;
}

.lbl-toggle:hover {
    color: #9fc3d4;
}

.lbl-toggle::before {
    content: ' ';
    display: inline-block;

    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: .7rem;
    transform: translateY(-2px);

    transition: transform .2s ease-out;
}

.lbl-toggle-deco {
    display: block;

    font-weight: bold;
    font-family: monospace;
    font-size: 2rem;
    text-transform: uppercase;
    text-align: center;
    text-decoration: underline;

    padding: 1rem;

    cursor: pointer;

    transition: all 0.25s ease-out;
    /*margin-bottom: -1.75rem;*/
}

.lbl-toggle-deco:hover {
    font-size: 2.2rem;
}

.lbl-toggle-deco::before {
    content: ' ';
    display: inline-block;

    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: .7rem;
    transform: translateY(-2px);

    transition: transform .2s ease-out;
}

.toggle:checked + .lbl-toggle::before {
    transform: rotate(90deg) translateX(-3px);
}

.toggle:checked + .lbl-toggle-deco::before {
    transform: rotate(90deg) translateX(-3px);
}

.collapsible-content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height .25s ease-in-out;
}

.collapsible-content-deco {
    max-height: 0px;
    overflow: hidden;
    transition: max-height .25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
    max-height: 1000vh;
}

.toggle:checked + .lbl-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.toggle:checked + .lbl-toggle-deco + .collapsible-content-deco {
    max-height: 1000vh;
}

.toggle:checked + .lbl-toggle-deco {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.collapsible-content .content-inner {
    background: rgba(143, 160, 170, 0.2);
    border-bottom: 1px solid rgba(51, 128, 149, 0.45);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: .5rem 1rem;
}

.collapsible-content-deco .content-inner-deco {
    background: rgba(143, 160, 170, 0.2);
    border-bottom: 2px solid black;
    border-top: 3px solid black;
    border-left: 5px solid black;
    border-right: 5px solid black;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: 0.5rem 0.5rem;
    margin-top: 0rem;
}

.tinyText {
    font-size: small;
}

.checkbox {
    width: 20px;
    position: relative;
    vertical-align: middle;
}
.scrollbar {
    overflow-y: scroll;
}
.inline{
    display:flex;
}
